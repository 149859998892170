// import * as jQuery from "jquery"
import 'jquery';
import * as bootbox from 'bootbox';
// mount modal for error report:
import ReportErrorModal from './report-error-modal.js';
import { removeLoadingAnimation } from './loading-overlay.js';
import { confirmDialog } from './confirm-dialog.js';
// universal caller for server requests
const PAF = {};

// error manager:
PAF.errorManager = (status, call, noReport, noMessage, error) => {
  // always remove loading animation:
  removeLoadingAnimation();
  // first, figure out what kind of response we got:
  if (noReport && !noMessage && error) {
    bootbox.alert(error);
  } else if (noReport && noMessage) {
    // do nothing
  } else if (status == 401) {
    // show dialog and offer login:
    const errorMessage =
      'Your session has expired or you are not logged in.' +
      ' Would you like to log back in?';
    const functionOnConfirm = () => {
      window.location.replace(
        `/auth?redir=${encodeURIComponent(window.location)}`,
      );
    };
    confirmDialog(errorMessage, functionOnConfirm);
  } else {
    PAF.reportError(error, call);
  }
};

// adding report error tool:

PAF.reportError = function (error, apiCall) {
  // create error modal:
  const reportErrorModal = new ReportErrorModal({
    error,
    apiCall,
  });
  reportErrorModal.mount('.modal-space');
};

PAF.getProjectName = function () {
  return window.location.href.split('/')[2].split('.')[0];
};

PAF.getFunctionality = function () {
  return window.location.href.split('/').pop().split('?')[0];
};

// function for reading variables from URL
PAF.getUrlVars = function getUrlVars() {
  const vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });
  return vars;
};

PAF.WebSocket = function (path, queryparams) {
  if (queryparams) {
    path = `${path}?${jQuery.param(queryparams)}`;
  }
  var loc = window.location;
  var new_uri;
  if (loc.protocol === 'https:') {
    new_uri = 'wss:';
  } else {
    new_uri = 'ws:';
  }
  new_uri += `//${loc.host}`;
  if (!path.startsWith('/')) {
    new_uri += '/';
  }
  new_uri += path;
  return new WebSocket(new_uri);
};

PAF.Call = async function (call, params, opts) {
  if (typeof opts === 'undefined') {
    opts = {};
  }
  if (typeof params === 'undefined') {
    params = {};
  }
  const noReport = opts.noReport || false;
  const noMessage = opts.noMessage || false;
  let method = opts.method || null;
  const paramsIsQuery = opts.paramsIsQuery || false;
  const headers = opts.headers || {};
  const jsonTable = opts.jsonTable || false;
  // create event that lets the timer reset:
  const apiCallEvent = new CustomEvent('apiCallEvent', { detail: 'API Call' });
  document.dispatchEvent(apiCallEvent);
  if (!method) {
    method = 'POST';
    if (params.length == 0 || paramsIsQuery) {
      method = 'GET';
    }
  }
  const body = method !== 'GET' ? JSON.stringify(params) : null;
  let querystring = method === 'GET' ? `?${jQuery.param(params)}` : '';
  // console.log(`/api/${call}`, JSON.stringify(params), jQuery.param(params), params);
  if (body) {
    headers['Content-Type'] = 'application/json; charset=utf-8';
  }
  const fetchOptions = {
    headers,
    body,
    mode: 'cors',
    method, // *GET, POST, PUT, DELETE, etc.
  };
  const urlParams = PAF.getUrlVars();
  if ('token' in urlParams) {
    fetchOptions.headers['X-Paf-Token'] = urlParams.token;
    fetchOptions.credentials = 'same-origin';
  }
  if (querystring === '?') {
    querystring = '';
  }
  const response = await fetch(
    `/api/${call}${querystring}`,
    fetchOptions,
  ).catch((error_condition) => {
    // make sure to remove loading indicator
    removeLoadingAnimation();
    // TypeError: NetworkError when attempting to fetch resource
    // Check for the above type
    if (error_condition.name === 'TypeError') {
      // Ignore errorManager
    } else {
      PAF.errorManager(error_condition); // , call, noReport, noMessage);
    }
    console.log(`${call} failed: ${error_condition}`);
    return Promise.reject(error_condition);
  });
  const contentType = response.headers.get('content-type');
  const isJson = contentType && contentType.includes('application/json');
  // console.log(response);
  if (!response.ok) {
    let errorMessage;
    if (response.status < 500) {
      if (isJson) {
        errorMessage = await response.json();
      } else {
        errorMessage = await response.text();
      }
      PAF.errorManager(
        response.status,
        call,
        noReport,
        noMessage,
        errorMessage,
      );
    } else {
      let respBody = await response.text();
      errorMessage = `${response.statusText}\n${respBody}`;
      PAF.errorManager(
        response.status,
        call,
        noReport,
        noMessage,
        `${response.status}: ${errorMessage}`,
      );
    }
    return Promise.reject(errorMessage);
  }
  if (!isJson) {
    return response.text();
  }
  if (jsonTable) {
    return response.json().then((r) => {
      const headers = r.headers;
      const data = r.data;
      const jsonObjList = [];
      for (const row of data) {
        const jsonObj = {};
        for (let i = 0; i < headers.length; i++) {
          jsonObj[headers[i]] = row[i];
        }
        jsonObjList.push(jsonObj);
      }
      return jsonObjList;
    });
  }
  return response.json();
};

// For backwards compatability
PAF.Query = async function (
  call,
  params,
  noReport = false,
  noMessage = false,
  method = null,
) {
  if (typeof params === 'undefined') {
    method = 'GET';
  }
  const opts = {
    method,
    noReport,
    noMessage,
  };
  return PAF.Call(call, params, opts);
};

PAF.getUserInfo = function () {
  if (localStorage.getItem('user_info')) {
    return new Promise((resolve) => {
      const userInfo = JSON.parse(localStorage.getItem('user_info'));
      resolve(userInfo);
    });
  }
  // user get info is now cached on API side
  return PAF.Call('user/v2/getUserInfo', {}, {
    noReport: true,
    noMessage: true,
  }).then((user) => {
    localStorage.setItem('user_info', JSON.stringify(user));
    return user;
  }).catch(() => null);
};

PAF.getProjectInfo = function () {
  // if (localStorage.getItem('project_info')) {
  //   return new Promise((resolve) => {
  //     const projectInfo = JSON.parse(localStorage.getItem('project_info'));
  //     resolve(projectInfo);
  //   });
  // }
  // user get info is now cached on API side
  return PAF.Call('project/v2/getProjectInfo', {}, {
  }).then((project) => {
    localStorage.setItem('project_info', JSON.stringify(project));
    return project;
  }).catch(() => null);
};

PAF.logout = function () {
  PAF.Query('auth/logout').then(() => {
    localStorage.removeItem('user_info');
    localStorage.removeItem('project_info');
    window.location.replace(
      `/auth?redir=${encodeURIComponent(window.location.pathname)}`,
    );
  });
};

export default PAF;
